import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PagesIcon from "@material-ui/icons/Pages";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CategoryIcon from "@material-ui/icons/Category";
import AttachmentIcon from '@material-ui/icons/Attachment';
import HomeIcon from "@material-ui/icons/Home";
import { useRouter } from 'next/router';
import Divider from '@material-ui/core/Divider';

const ListItems = () => {
  const router = useRouter();
  
  return (
    <div>
      <List style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItem button onClick={() => router.push("/home")} key="Αρχική">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Αρχική" />
        </ListItem>
      </List>
      <Divider />
      <List style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItem button onClick={() => router.push("/pages")} key="Σελίδες">
          <ListItemIcon>
            <PagesIcon />
          </ListItemIcon>
          <ListItemText primary="Σελίδες" />
        </ListItem>
      </List>
      <Divider />
      <List style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItem button onClick={() => router.push("/posts")} key="Άρθρα">
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Άρθρα" />
        </ListItem>
      </List>
      <Divider />
      <List style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItem button onClick={() => router.push("/categories")} key="Κατηγορίες">
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Κατηγορίες" />
        </ListItem>
      </List>
      <Divider />
      <List style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItem button onClick={() => router.push("/medias")} key="Αρχεία">
          <ListItemIcon>
            <AttachmentIcon />
          </ListItemIcon>
          <ListItemText primary="Αρχεία" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );
};
export default ListItems;
